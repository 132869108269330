<template>
    <footer class="pt-6 w-full bg-primary-emphasis bg-opacity-60">
        <div class="flex flex-col sm:flex-row sm:items-start justify-around">
            <div class="">
                <div class="flex flex-col items-center">
                    <strong
                        class="text-lg text-primary-emphasis dark:text-white"
                    >
                        Social Networks
                    </strong>
                    <div class="flex justify-center my-1">
                        <a
                            href="https://www.linkedin.com/company/innovalombia"
                            target="_blank"
                            rel="noopener"
                            aria-label="Instagram page"
                        >
                            <linked-in-icon
                                class="icon w-8 h-8 text-primary-secondary"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="flex flex-col items-center">
                    <strong
                        class="text-lg text-primary-emphasis dark:text-white"
                    >
                        Author
                    </strong>
                    <div class="">
                        <a
                            href="https://www.innovalombia.com"
                            target="_blank"
                            rel="noopener"
                            aria-label="Innovalombia page"
                            class="mr-1 flex items-center my-2"
                        >
                            <img
                                class="h-6 sm:h-8 lg:h-10"
                                src="/img/logo.png"
                                :alt="`Logo ${$siteTitle}`"
                                loading="lazy"
                            />
                            <span class="text-primary-secondary">
                                Innovalombia
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="flex flex-col">
                    <div class="flex justify-center">
                        <strong
                            class="text-lg text-primary-emphasis dark:text-white"
                        >
                            Others Tools
                        </strong>
                    </div>

                    <a
                        aria-label="blog"
                        href="https://posix.innovalombia.com"
                        target="_blank"
                        rel="noopener"
                        class="no-underline hover:underline hover:text-primary-secondary"
                    >
                        <span>Posix Converter</span>
                    </a>
                    <a
                        aria-label="blog"
                        href="https://json.innovalombia.com"
                        target="_blank"
                        rel="noopener"
                        class="no-underline hover:underline hover:text-primary-secondary"
                    >
                        <span>JSON Tools</span>
                    </a>
                    <a
                        aria-label="blog"
                        href="https://textcases.innovalombia.com"
                        target="_blank"
                        rel="noopener"
                        class="no-underline hover:underline hover:text-primary-secondary"
                    >
                        <span>Text Tools</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="mx-auto px-6 mt-4">
            <div
                class="border-t-2 border-primary-emphasis flex flex-col items-center"
            >
                <div
                    class="sm:w-2/3 flex flex-col items-center justify-center py-6"
                >
                    <p
                        class="text-sm text-primary-emphasis dark:text-white font-bold"
                    >
                        © {{ new Date().getFullYear() }}

                        <a href="/" class="no-underline hover:underline">
                            JSON Tools
                        </a>
                    </p>
                    <span>💛💙❤️</span>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import LinkedInIcon from '@/components/icons/Linkedin.vue';

export default {
    name: 'FooterMain',
    components: { LinkedInIcon },
    data: () => ({}),
    methods: {}
};
</script>
