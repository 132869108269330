<template>
    <main class="p-0 m-0" :class="isDark ? 'dark' : ''">
        <div
            class="content-layout dark:bg-primary-dark text-black dark:text-white"
        >
            <header-main />
            <div class="flex flex-1 flex-col mt-24 container mx-auto">
                <router-view />
            </div>
            <footer-main />
        </div>
    </main>
</template>

<script>
import { storeToRefs } from 'pinia';

import FooterMain from '@/components/layout/FooterMain.vue';
import HeaderMain from '@/components/layout/HeaderMain.vue';
import { useControlStore } from '@/stores/control';

export default {
    name: 'DefaultLayout',
    components: {
        HeaderMain,
        FooterMain
    },
    setup() {
        const { isDark } = storeToRefs(useControlStore());
        return {
            isDark
        };
    }
};
</script>
