<template>
    <div class="mx-auto container h-screen flex items-center justify-center">
        <div class="w-full text-center">
            <span class="text-9xl block">404</span>
            <div class="mb-12 text-2xl">Not found page.</div>
            <router-link
                to="/"
                class="border-primary-emphasis border hover:bg-primary-emphasis hover:text-white text-primary-emphasis font-bold py-2 px-4 rounded"
                aria-label="Go to home"
            >
                Go to home
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
};
</script>
