<template>
    <a
        class="overflow-hidden md:w-auto h-16 flex items-center"
        :href="$publicUrl"
    >
        <picture>
            <source
                srcset="/img/icon.png"
                :loading="lazy ? 'lazy' : ''"
                width="180"
                height="113"
                media="(min-width:900px)"
                x-content-type-options="nosniff"
            />
            <img
                src="/img/icon.png"
                :alt="`${$siteTitle} | Inicio`"
                width="64"
                height="64"
                class="md:h-16 md:w-auto"
                :loading="lazy ? 'lazy' : ''"
                :title="`${$siteTitle} | Inicio`"
            />
        </picture>
    </a>
</template>

<script>
export default {
    name: 'LogoMain',
    props: {
        lazy: { type: Boolean, default: () => false, required: false }
    }
};
</script>
