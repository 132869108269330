import { createRouter, createWebHistory } from 'vue-router';

import DefaultLayout from '../layouts/default.vue';
import IndexPage from '../pages/index.vue';
import NotFound from '../pages/not-found.vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'index',
            component: DefaultLayout,
            children: [
                {
                    path: '',
                    component: IndexPage,
                    name: 'IndexPage',
                    meta: {
                        pageTitle: 'Onboarding'
                    }
                }
            ]
        },
        {
            path: '/not-found',
            name: 'NotFount',
            component: NotFound
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/not-found'
        }
    ]
});

export default router;
