<template>
    <div class="h-120 w-80 sm:w-full">
        <code-editor
            v-model="code"
            class="border border-gray-400 rounded-md h-full"
            height="100%"
            width="100%"
            :read-only="readOnly"
            :tab-spaces="4"
            :line-nums="true"
            :languages="languages"
            :theme="isDark ? 'github-dark' : 'a11y-light'"
            @update:modelValue="changeInput"
        ></code-editor>
    </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'highlight.js';
import { storeToRefs } from 'pinia';
import CodeEditor from 'simple-code-editor';
import { defineComponent, reactive, toRefs, watch } from 'vue';

import { useControlStore } from '@/stores/control';

export default defineComponent({
    components: { CodeEditor },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        readOnly: {
            type: Boolean,
            default: () => false,
            required: false
        },
        value: {
            type: String,
            default: () => '',
            required: true
        },
        languages: {
            type: Array,
            default: () => [['json', 'JSON']],
            required: false
        }
    },
    setup(props, { emit }) {
        const { isDark } = storeToRefs(useControlStore());

        const state = reactive({
            code: props.value
        });
        watch(
            () => props.value,
            (first) => {
                state.code = `${first}`;
            }
        );
        const changeInput = (event: string) => {
            emit('update:value', event);
        };
        return {
            ...toRefs(state),
            changeInput,
            isDark
        };
    }
});
</script>
