:
<template>
    <div class="w-full">
        <div>
            <div class="flex flex-col justify-between sm:flex-row px-3">
                <div>
                    <button
                        class="mt-2 border-primary-primary border hover:bg-white bg-primary-primary text-white hover:text-primary-primary font-bold py-2 px-4 rounded"
                        @click="processFile"
                    >
                        Process {{ isProcessing ? '..' : '' }}
                    </button>
                </div>
                <span
                    v-if="reasonForInvalidity"
                    class="text-primary-primary dark:text-primary-secondary text-lg my-3 hidden md:block"
                >
                    JSON is invalid, details: {{ reasonForInvalidity }}
                </span>
            </div>
        </div>

        <div class="flex flex-wrap justify-between">
            <div class="md:w-1/2 w-full px-3 mt-5">
                <div>
                    <span class="font-semibold text-lg"> JSON Input </span>
                </div>
                <code-editor v-model:value="input" :languages="[['text']]" />
            </div>
            <div id="json-deserialize-result" class="md:w-1/2 w-full px-3 mt-5">
                <div class="flex flex-col">
                    <span class="font-semibold text-lg"> JSON Output </span>
                    <span
                        v-if="reasonForInvalidity"
                        class="text-yellow-500 text-lg my-3 md:hidden"
                    >
                        JSON is invalid, details: {{ reasonForInvalidity }}
                    </span>
                </div>
                <code-editor v-model:value="result" read-only />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import type { JsonAdapter } from 'inversify-utils-kit';
import { defineComponent, inject, reactive, toRefs } from 'vue';

import CodeEditor from '@/components/utils/CodeEditor.vue';

export default defineComponent({
    components: { CodeEditor },
    setup() {
        const jsonAdapter = inject('jsonAdapter') as JsonAdapter;

        const state = reactive({
            input: '',
            result: '',
            reasonForInvalidity: '',
            isProcessing: false
        });

        const goToResultContainer = () => {
            const section = document.getElementById('json-deserialize-result');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        };

        const processFile = () => {
            state.isProcessing = true;
            state.reasonForInvalidity = '';
            const jsonDeserialize = jsonAdapter.deserializeJson(state.input);
            if (jsonDeserialize.reasonForInvalidity) {
                state.reasonForInvalidity = jsonDeserialize.reasonForInvalidity;
                state.result = '';
                state.isProcessing = false;
                return;
            }
            state.result = jsonAdapter.parseJsonStringToString(
                jsonAdapter.serializeJson(jsonDeserialize.result).result
            ).result;
            goToResultContainer();
            state.isProcessing = false;
        };

        return { processFile, ...toRefs(state) };
    }
});
</script>
