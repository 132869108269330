<template>
    <div class="flex mt-1">
        <div class="flex justify-center items-center mx-2">
            <span
                v-show="showPulse && !stopPulse"
                class="animate-ping absolute inline-flex h-8 w-8 rounded-full bg-primary-emphasis opacity-75"
                @click="stopPulse = true"
            ></span>
            <input
                :id="id"
                v-model="field"
                class="hidden"
                type="checkbox"
                @click="clickCheck"
            />
            <label :for="id" class="flex items-center cursor-pointer">
                <div
                    :class="
                        field
                            ? `h-6 w-6 border border-${primaryColor} focus:ring-${primaryColor} focus:border-${primaryColor} transition-all rounded-md bg-${primaryColor} checked`
                            : `h-6 w-6 border border-${primaryColor} focus:ring-${primaryColor} focus:border-${primaryColor} transition-all rounded-md`
                    "
                ></div>
            </label>
        </div>
        <div
            v-if="enableMultiple"
            class="relative w-full cursor-pointer"
            @mouseenter="showOptions = true"
            @mouseleave="showOptions = false"
        >
            <slot name="content"></slot>
            <button
                v-show="showOptions"
                type="button"
                class="absolute right-2 z-90 mt-1 text-xs rounded-lg border px-1 bg-white dark:bg-primary-dark border-gray-400 text-gray-500"
                @click="clickMultipleOptions"
            >
                {{ allChecked || !field ? 'Unico' : 'Todos' }}
            </button>
        </div>
        <div v-else>
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckboxComponent',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        showPulse: { type: Boolean, default: () => false, required: false },
        enableMultiple: {
            type: Boolean,
            default: () => false,
            required: false
        },
        value: {
            type: Boolean,
            default: () => true,
            required: true
        },
        id: {
            type: [String, Number],
            required: false,
            default: () => `id_${Math.random() * 100}`
        },
        allChecked: { type: Boolean, default: () => false, required: false },
        primaryColor: {
            type: String,
            default: () => 'primary-details',
            required: false
        }
    },
    emits: ['update:value', 'click', 'selectOnly', 'selectAll'],
    data: () => ({
        field: false,
        stopPulse: false,
        showOptions: false
    }),
    watch: {
        value(value) {
            this.field = value;
        },
        field(value) {
            this.$emit('update:value', value);
        }
    },
    mounted() {
        this.field = Boolean(this.value);
    },
    methods: {
        clickCheck() {
            this.$emit('click', { checked: !this.field, value: this.id });
        },
        clickMultipleOptions() {
            if (this.allChecked || !this.field) {
                this.$emit('selectOnly', this.id);
            } else {
                this.$emit('selectAll');
            }
        }
    }
};
</script>
