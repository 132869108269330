import 'reflect-metadata';

import { Container } from 'inversify';
import {
    DefaultJsonAdapter,
    JSON_ADAPTER_TYPE,
    type JsonAdapter
} from 'inversify-utils-kit';

const AppContainer: Container = new Container();

AppContainer.bind<JsonAdapter>(JSON_ADAPTER_TYPE).to(DefaultJsonAdapter);

export { AppContainer };
