<template>
    <div class="w-full">
        <div>
            <div class="flex flex-col justify-between sm:flex-row px-3">
                <div class="flex flex-col">
                    <div class="mt-2 flex flex-col sm:flex-row">
                        <div class="flex items-center">
                            <span class="mr-2"> JSON format </span>
                            <checkbox-component v-model:value="needJson" />
                        </div>
                        <div class="flex items-center" v-if="!needJson">
                            <span class="mr-2"> Escape quotes (") </span>
                            <checkbox-component
                                v-model:value="needEscapeQuotes"
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            class="mt-2 border-primary-primary border hover:bg-white bg-primary-primary text-white hover:text-primary-primary font-bold py-2 px-4 rounded"
                            @click="processFile"
                        >
                            Process
                        </button>
                    </div>
                </div>
                <span
                    v-if="reasonForInvalidity"
                    class="text-primary-primary dark:text-primary-secondary text-lg my-3 hidden md:block"
                >
                    JSON is invalid, details: {{ reasonForInvalidity }}
                </span>
            </div>
        </div>

        <div class="flex flex-wrap justify-between">
            <div class="md:w-1/2 w-full px-3 mt-5">
                <div>
                    <span class="font-semibold text-lg"> JSON Input </span>
                </div>
                <code-editor v-model:value="input" :languages="[['json']]" />
            </div>
            <div id="json-serialize-result" class="md:w-1/2 w-full px-3 mt-5">
                <div class="flex flex-col">
                    <span class="font-semibold text-lg"> Output </span>
                    <span
                        v-if="reasonForInvalidity"
                        class="text-yellow-500 text-lg my-3 md:hidden"
                    >
                        JSON is invalid, details: {{ reasonForInvalidity }}
                    </span>
                </div>
                <code-editor v-model:value="result" read-only />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import type { JsonAdapter } from 'inversify-utils-kit';
import { defineComponent, inject, reactive, ref, toRefs } from 'vue';

import CheckboxComponent from '@/components/utils/CheckboxComponent.vue';
import CodeEditor from '@/components/utils/CodeEditor.vue';

export default defineComponent({
    components: { CodeEditor, CheckboxComponent },
    setup() {
        const jsonAdapter = inject('jsonAdapter') as JsonAdapter;

        const needJson = ref(false);
        const needEscapeQuotes = ref(false);
        const state = reactive({
            input: '{}',
            result: '',
            reasonForInvalidity: '',
            isProcessing: false
        });
        const goToResultContainer = () => {
            const section = document.getElementById('json-serialize-result');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        };

        const processFile = () => {
            state.isProcessing = true;
            state.reasonForInvalidity = '';
            const jsonFormatter = jsonAdapter.parseStringToJson(state.input);
            if (jsonFormatter.reasonForInvalidity) {
                state.reasonForInvalidity = jsonFormatter.reasonForInvalidity;
                state.result = '';
                state.isProcessing = false;
                return;
            }
            if (needJson.value) {
                const jsonSerialize = jsonAdapter.serializeJson(
                    jsonFormatter.result,
                    false
                ).result;
                const jsonParsed = jsonAdapter.parseJsonToString(
                    { result: jsonSerialize },
                    false
                ).result;
                state.result = jsonParsed;
            } else {
                const jsonSerialize = jsonAdapter.serializeJson(
                    jsonFormatter.result,
                    needEscapeQuotes.value
                ).result;
                state.result = jsonSerialize;
            }
            if (needEscapeQuotes.value) {
                state.result = `"${state.result}"`;
            }
            goToResultContainer();
            state.isProcessing = false;
        };

        return { processFile, ...toRefs(state), needEscapeQuotes, needJson };
    }
});
</script>
