<template>
    <header id="header-main" class="w-full shadow">
        <div class="flex flex-wrap bg-white dark:bg-primary-dark py-2">
            <div class="hidden md:block md:w-1/6"></div>
            <div class="w-full md:w-4/6 justify-center flex items-center">
                <h1 class="text-3xl mx-4 font-semibold">
                    <span
                        class="text-primary-emphasis dark:text-primary-secondary-"
                    >
                        JSON
                    </span>
                    <span class="text-primary-primary"> Tools </span>
                </h1>
                <logo-main />
            </div>
            <div class="w-full md:w-1/6 flex justify-center"></div>
        </div>
    </header>
</template>

<script>
import LogoMain from '@/components/layout/LogoMain.vue';

export default {
    components: {
        LogoMain
    },
    props: {}
};
</script>
