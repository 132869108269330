<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <path
            d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"
            fill-rule="evenodd"
            clip-rule="evenodd"
            class="fill-000000"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'LinkedinIcon'
};
</script>
