import { JSON_ADAPTER_TYPE, type JsonAdapter } from 'inversify-utils-kit';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';

import App from './App.vue';
import './assets/main.css';
import { HOST, SITE_TITLE } from './common/constants';
import { AppContainer } from './config';
import router from './router';

const app = createApp(App);
app.config.globalProperties.$publicUrl = HOST;
app.config.globalProperties.$siteTitle = SITE_TITLE;
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);
app.use(createMetaManager());
app.use(metaPlugin);

const jsonAdapter = AppContainer.get<JsonAdapter>(JSON_ADAPTER_TYPE);
app.provide('jsonAdapter', jsonAdapter);

app.mount('#app');
