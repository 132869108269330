<template>
    <div class="shadow">
        <button
            type="button"
            class="no-underline hover:underline"
            @click="toggleDark"
        >
            {{ isDark ? 'Light 🌞' : 'Dark 🌛' }}
        </button>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useControlStore } from '@/stores/control';

const { isDark } = storeToRefs(useControlStore());
const { toggleDark } = useControlStore();
</script>
