<template>
    <div class="px-2 mb-20 w-full">
        <div id="title">
            <p class="text-black dark:text-white text-justify">
                Intuitive tool for serializing and deserializing files in JSON
                format. This tool enables users to efficiently and easily
                transform data structures into JSON text and vice versa. The
                platform is designed to be user-friendly and accessible, making
                data reading and manipulation straightforward for both
                developers and less technically experienced users. With our
                solution, managing data in JSON format becomes a quick and
                hassle-free process.
            </p>
            <div class="flex flex-col md:flex-row justify-center mt-4">
                <a
                    href="#json-serializer"
                    class="no-underline hover:underline text-primary-primary mx-2"
                >
                    JSON serializer
                </a>
                <a
                    href="#json-deserializer"
                    class="no-underline hover:underline text-primary-primary mx-2"
                >
                    JSON deserializer
                </a>
                <a
                    href="#json-formatter"
                    class="no-underline hover:underline text-primary-primary mx-2"
                >
                    JSON formatter
                </a>

                <a
                    href="#whats-different"
                    class="no-underline hover:underline text-primary-primary mx-2"
                >
                    What makes this site different ?
                </a>
                <change-theme class="mx-2" />
            </div>
        </div>
        <div id="json-serializer" class="mt-8 pt-4">
            <h2 class="text-xl font-semibold dark:text-white">
                Serialize JSON
            </h2>

            <div class="mt-4">
                <p class="text-black dark:text-white text-justify">
                    To transform JSON file like :
                </p>
                <pre
                    class="mt-3 font-mono bg-gray-700 text-green-400 p-4 rounded mb-2"
                >
{
    "amount": 20000
}</pre
                >

                <p class="text-black dark:text-white text-justify">
                    In a text string like:
                    <in-line-code-viewer>
                        "{\"amount\":20000}" </in-line-code-viewer
                    >. Paste the JSON into the text editor. Click in "Process"
                    button and get result. If you want the result to be with
                    escaped quotes, click on "Escape quotes". If you want the
                    result to be another JSON file with a field with the
                    original JSON serialized, click on "JSON format".
                </p>
            </div>
            <json-serializer class="mt-4" />
        </div>
        <div id="json-deserializer" class="mt-8 pt-4">
            <h2 class="text-xl font-semibold dark:text-white">
                JSON deserializer
            </h2>
            <div class="mt-4">
                <p class="text-black dark:text-white text-justify">
                    To transform a serialized JSON string like
                    <in-line-code-viewer>
                        "{\"amount\":20000}"
                    </in-line-code-viewer>
                    or a compact JSON object like
                    <in-line-code-viewer>
                        {"amount":20000}
                    </in-line-code-viewer>
                    &nbsp;into a formatted JSON object as shown below&nbsp;:
                </p>
                <pre
                    class="mt-3 font-mono bg-gray-700 text-green-400 p-4 rounded mb-2"
                >
{
    "amount": 20000
}</pre
                >
                <p class="text-black dark:text-white text-justify">
                    Paste the serialized JSON string into the text editor. Click
                    in "Process" button and get result.
                </p>
            </div>
            <json-deserializer class="mt-4" />
        </div>
        <div id="json-formatter" class="mt-8 pt-4">
            <h2 class="text-xl font-semibold dark:text-white">
                JSON formatter
            </h2>
            <div class="mt-4">
                <p class="text-black dark:text-white text-justify">
                    Paste a json file and format to easy to read by humans.
                </p>
            </div>
            <json-formatter class="mt-4" />
        </div>

        <div id="whats-different" class="mt-8 pt-4">
            <h2 class="text-xl font-semibold dark:text-white">
                What makes this site different ?
            </h2>
            <ul class="list-disc pl-6 pr-2 my-6">
                <li class="mb-4">
                    <p class="text-justify">
                        It allows you to convert serialized json to json
                        formatted in a human-readable format.
                    </p>
                </li>
                <li class="mb-4">
                    <p class="text-justify">
                        It allows you to view a JSON file with a special format
                        that allows you to save the result in another JSON,
                        escaping the
                        <in-line-code-viewer>"</in-line-code-viewer> a
                        <in-line-code-viewer>/"</in-line-code-viewer>
                        characters.
                    </p>
                </li>
            </ul>
        </div>
        <div id="whats-different" class="mt-8 pt-4">
            <h2 class="text-xl font-semibold dark:text-white">
                What is JSON Serialization and Deserialization?
            </h2>
            <div class="my-6">
                <p>
                    <strong>Serialization</strong> is the process of converting
                    a data structure (such as an object in a programming
                    language) into a text string in JSON format. JSON
                    (JavaScript Object Notation) is a lightweight text format
                    for data exchange that is easy to read and write for both
                    humans and machines.
                </p>
                <br />
                <p>
                    <strong>Deserialization </strong> is the reverse process,
                    where a text string in JSON format is taken and converted
                    back into a data structure usable by a program
                </p>
            </div>

            <h3 class="text-xl font-semibold dark:text-white mt-3">
                Utility and Storage in Databases
            </h3>
            <div class="my-6">
                <p>
                    By serializing data to JSON, you can easily save blocks of
                    complex information as text in databases. This is especially
                    useful for NoSQL systems like MongoDB, where documents are
                    stored directly in JSON format. It is also practical for
                    relational systems, as it allows data structures to be
                    stored in text fields.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useMeta } from 'vue-meta';

import InLineCodeViewer from '@/components/json/InLineCodeViewer.vue';
import JsonDeserializer from '@/components/json/JsonDeserializer.vue';
import JsonFormatter from '@/components/json/JsonFormatter.vue';
import JsonSerializer from '@/components/json/JsonSerializer.vue';
import ChangeTheme from '@/components/utils/ChangeTheme.vue';

export default {
    name: 'IndexPage',
    components: {
        ChangeTheme,
        JsonSerializer,
        JsonFormatter,
        JsonDeserializer,
        InLineCodeViewer
    },
    setup() {
        useMeta({
            title: 'JSON Tools',
            meta: [
                {
                    name: 'description',
                    content:
                        'Tool for serializing and deserializing files in JSON format. This tool enables users to efficiently and easily transform data structures into JSON text and vice versa.'
                },
                {
                    name: 'keywords',
                    content:
                        'JSON serialization, JSON deserialization, JSON, JSON data manipulation'
                },
                {
                    name: 'og:image:secure_url',
                    content: `/img/index.jpg`
                },
                { name: 'og:image:height', content: '627' },
                { name: 'og:image:width', content: '1200' },
                {
                    name: 'og:image:alt',
                    content:
                        'Tool for JSON serialization, deserialization and formatting'
                },
                { name: 'og:image:type', content: 'image/jpg' }
            ],
            htmlAttrs: { lang: 'en', amp: true }
        });
        const state = reactive({
            jsonInstances: [{ id: 1, name: 'Default' }],
            datesInstances: [{ id: 1, name: '', json: 0 }]
        });
        const newJSON = () => {
            const lastJSON = state.jsonInstances.at(-1);
            state.jsonInstances.push({
                id: lastJSON.id + 1,
                name: 'New Date'
            });
        };

        const newDate = () => {
            const lastDate = state.datesInstances.at(-1);
            state.datesInstances.push({
                id: lastDate.id + 1,
                name: 'New Date'
            });
        };

        return {
            ...toRefs(state),
            newJSON,
            newDate
        };
    }
};
</script>
