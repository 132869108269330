import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useControlStore = defineStore(
    'control',
    () => {
        const isDark = ref(false);
        function toggleDark() {
            isDark.value = !isDark.value;
        }

        return { isDark, toggleDark };
    },
    {
        persist: {
            key: 'control'
        }
    }
);
